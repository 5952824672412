const roles = {
    signin: 'rdg-ingest-sign-in',

    record_create: 'rdg-create-records',
    record_edit: 'rdg-edit-records',
    record_delete: 'rdg-delete-records',
    record_view: 'rdg-view-records',
    record_publish: 'rdg-publish-records',
    record_unpublish: 'rdg-unpublish-records',
    record_update: 'rdg-update-records',
    record_search: 'rdg-search-records',

    batch_create: 'rdg-batch-create-records',
    batch_edit: 'rdg-batch-edit-records',
    batch_publish: 'rdg-batch-publish-records'
}

export { roles };