export const ADMINS = {
    admin_icpsr: 'rdg-icpsr-admins'
}

export const ID_TO_AGENCY = {
    '1': 'Bureau of Economic Analysis',
    '2': 'Bureau of Justice Statistics',
    '3': 'Bureau of Labor Statistics',
    '4': 'Bureau of Transportation Statistics',
    '5': 'Census Bureau',
    '6': 'Economic Research Service',
    '7': 'Energy Information Administration',
    '8': 'National Agricultural Statistics Service',
    '9': 'National Center for Education Statistics',
    '10': 'National Center for Health Statistics',
    '11': 'National Center for Science and Engineering Statistics',
    '12': 'Office of Research, Evaluation, and Statistics',
    '13': 'Statistics of Income Division',
    '14': 'Microeconomic Surveys Unit',
    '15': 'Center for Behavioral Health Statistics and Quality',
    '16': 'National Animal Health Monitoring System'
}

export const AGENCY_TO_ID = (() => {
    const map = {};
    Object.keys(ID_TO_AGENCY).forEach(key => map[ID_TO_AGENCY[key]] = key);
    return map;
})();

// this groups are templates. the roles in this groups are copied over to corresponding match
export const TEMPLATE_GROUPS = {
    data_owner_agency_0: 'rdg-data-owner-0',
    rdg_metadata_team_0:  'rdg-metadata-team-0',
}

export const DATA_OWNERS = {
    data_owner_agency_1: 'rdg-data-owner-1',
    data_owner_agency_2: 'rdg-data-owner-2',
    data_owner_agency_3: 'rdg-data-owner-3',
    data_owner_agency_4: 'rdg-data-owner-4',
    data_owner_agency_5: 'rdg-data-owner-5',
    data_owner_agency_6: 'rdg-data-owner-6',
    data_owner_agency_7: 'rdg-data-owner-7',
    data_owner_agency_8: 'rdg-data-owner-8',
    data_owner_agency_9: 'rdg-data-owner-9',
    data_owner_agency_10: 'rdg-data-owner-10',
    data_owner_agency_11: 'rdg-data-owner-11',
    data_owner_agency_12: 'rdg-data-owner-12',
    data_owner_agency_13: 'rdg-data-owner-13',
    data_owner_agency_14: 'rdg-data-owner-14',
    data_owner_agency_15: 'rdg-data-owner-15',
    data_owner_agency_16: 'rdg-data-owner-16'
}

export const DATA_OWNERS_VALUES_ARRAY = Object.keys(DATA_OWNERS).map(x => DATA_OWNERS[x]);

export const METADATA_TEAM = {
    rdg_metadata_team_1:  'rdg-metadata-team-1',
    rdg_metadata_team_2:  'rdg-metadata-team-2',
    rdg_metadata_team_3:  'rdg-metadata-team-3',
    rdg_metadata_team_4:  'rdg-metadata-team-4',
    rdg_metadata_team_5:  'rdg-metadata-team-5',
    rdg_metadata_team_6:  'rdg-metadata-team-6',
    rdg_metadata_team_7:  'rdg-metadata-team-7',
    rdg_metadata_team_8:  'rdg-metadata-team-8',
    rdg_metadata_team_9:  'rdg-metadata-team-9',
    rdg_metadata_team_10: 'rdg-metadata-team-10',
    rdg_metadata_team_11: 'rdg-metadata-team-11',
    rdg_metadata_team_12: 'rdg-metadata-team-12',
    rdg_metadata_team_13: 'rdg-metadata-team-13',
    rdg_metadata_team_14: 'rdg-metadata-team-14',
    rdg_metadata_team_15: 'rdg-metadata-team-15',
    rdg_metadata_team_16: 'rdg-metadata-team-16'
}

export const METADATA_TEAM_VALUES_ARRAY = Object.keys(METADATA_TEAM).map(x => METADATA_TEAM[x]);

export const getGroupByID = (groupType, id) => {
    const match_group_key = Object.keys(groupType).find(group => {
        const split = group.split("_"); 
        return split[split.length - 1] == id;
    });

    if (match_group_key) {
        return groupType[match_group_key];
    }
}