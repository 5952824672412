import { AGENCY_TO_ID, ADMINS, DATA_OWNERS, METADATA_TEAM, getGroupByID } from '../radius-api/constants/groups';

const hasARole = (role, roleGroups) => {
    return !!Object.keys((roleGroups!=null) ? roleGroups : {}).find((group) => {
        return (roleGroups[group] || []).filter(r => r === role)[0];
    });
}

const hasAgencyRole = (agencyName, role, userRolesAndGroups) => {
    const agencyCode = AGENCY_TO_ID[agencyName];

    return hasAgencyRoleByCode(agencyCode, role, userRolesAndGroups);
}

const hasAgencyRoleByCode = (agencyCode, role, userRolesAndGroups) => {
    if (!agencyCode) {
        return false;
    }

    // these groups should give you access to certain role
    return !![
        ADMINS.admin_icpsr, 
        getGroupByID(DATA_OWNERS, agencyCode),
        getGroupByID(METADATA_TEAM, agencyCode)
    ]
        .find(group => {
            return !!(userRolesAndGroups && userRolesAndGroups[group] || []).filter(r => r === role)[0];
        })
}


export { hasARole, hasAgencyRole, hasAgencyRoleByCode };