import getConfig from "next/config";
import { useRouter } from "next/router";
import "../styles/global.css";
import "../styles/nprogress.css";
import { useContext, useEffect, useState } from "react";
import { UserProvider, useUserContext } from "../lib/context/userContext"
import NProgress from 'nprogress'
import Router from 'next/router'
import TagManager from 'react-gtm-module';
import 'bootstrap/dist/css/bootstrap.min.css';
import { library, config } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/pro-solid-svg-icons';
import '@fortawesome/fontawesome-svg-core/styles.css'
import { SessionProvider, useSession, signIn } from "next-auth/react";
import { KeycloakLoginComponent } from "@icpsr/auth/lib/esm";
config.autoAddCss = false
library.add(fas);



// Router.events.on('routeChangeStart', (url) => {
//   NProgress.start()
// })
// Router.events.on('routeChangeComplete', () => NProgress.done())
// Router.events.on('routeChangeError', () => NProgress.done())

// Client-side / public config
const clientSideConfig = getConfig();


export default function App({ Component, pageProps:{session, ...pageProps} }) {

  const isUnAuthenticatedPage = pageProps
    ? pageProps.isUnAuthenticatedPage
    : false;

  useEffect(() => {
    //Tag Manager Id might have to be updated when working on it. Add as part of inital commit as a reminder.
    TagManager.initialize({ gtmId: 'GTM-M7XM3G5' });
  }, []);
  return (
    <SessionProvider session={session}>
      {!isUnAuthenticatedPage ? (
        <Layout
          roleNeededForPageAccess={
            pageProps ? pageProps.roleNeededForPageAccess : null
          }
        >
          <Component {...pageProps} />
        </Layout>
      ) : (
        <Component {...pageProps} />
      )}
    </SessionProvider>
    
  );
}

function Layout({ children }) {
  const nextConfig = getConfig();
  const { data: session, status: loginStatus} = useSession();
  const router = useRouter();
  const providerId = nextConfig.publicRuntimeConfig.nextAuthKeycloakProviderId;
  const referrer = router.asPath;
  const [userProfileFetchSuccess, setUserProfileFetchSuccess] = useState(false);

  useEffect(() => {
    if (loginStatus === 'unauthenticated') {
      signIn(providerId, {
        callbackUrl: router.asPath,
      });
    } else if (loginStatus === 'authenticated') {
      if (
        session &&
        session.user &&
        session.user.profile &&
        !session.user.profile.error
      ) {
        setUserProfileFetchSuccess(true);
      } else {
        console.log('loginStatus:', loginStatus);
        router.push('/500');
      }
    }
  }, [session]);


  return (
    <>
     {loginStatus === 'loading' && <></>}
    {loginStatus === 'unauthenticated' && (
      <>
        <p className="text-center">Redirecting...</p>
      </>
    )}
    {loginStatus === 'authenticated' && userProfileFetchSuccess && (
          <UserProvider>
            {children}
          </UserProvider>
      )}

    </>
  );
}