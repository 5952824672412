import { createContext, useContext, useState, useEffect } from 'react';
import { useSession } from "next-auth/react";
import { hasARole } from '@/lib/api/server-side/radius-api/has-access';
import { roles } from '@/lib/api/server-side/radius-api/constants/roles';

const UserContext = createContext();
const userGroupsAndRolesFetch = async () => {
  let resp = {
    rolesAndGroups: {},
    roles:{}
  };
  try {
    const temp = await fetch("/api/auth/userPermissions");
    
    resp.rolesAndGroups = await temp.json();
    Object.keys(roles).forEach(role => {
      resp.roles[roles[role]] = hasARole(roles[role], resp.rolesAndGroups);
    });
    const isAllowed = hasARole(roles.signin, resp.rolesAndGroups);
    
    resp = {...resp, isAllowed: isAllowed};
    
  } catch (e) {
    console.log("user radius roles and group Fetch response error:", e);
  }
  return resp;
}
const UserProvider = ({ children }) => {
  const { data: session} = useSession();
  const [user, setUser] = useState((session && session.user) ? session.user : {rolesAndGroups:{}, roles:{}, isAllowed:false});

  useEffect(() => {
    if (session && session.user) {
      (
        async() => {
          const resp = await userGroupsAndRolesFetch();
          setUser({...session.user, ...resp});
      })();
    }
  }, [session]);
  return (
    <UserContext.Provider value={user}>
      {children}
    </UserContext.Provider>
  );
}

const useUserContext = () => {
  return useContext(UserContext);
}

export { UserProvider, useUserContext, UserContext }